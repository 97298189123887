import React, { FC } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import tokens from '@carta/ink/dist/tokens/rebrand.json';
import { COMPONENT_SLUGS_TO_NAMES, DEPRECATED_COMPONENT_NAMES } from '../../constants';

interface ComponentsChartProps {
  components: {
    [key in typeof COMPONENT_SLUGS_TO_NAMES[keyof typeof COMPONENT_SLUGS_TO_NAMES]]: {
      count: number;
    };
  };
}

const ComponentsChart: FC<ComponentsChartProps> = ({ components }) => {
  const filteredComponents = Object.keys(components).filter(c => components[c].count >= 1000);
  const sortedComponents = filteredComponents.sort((c1, c2) => components[c2].count - components[c1].count);
  const sortedCounts = sortedComponents.map(c => components[c].count);

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={{
        chart: {
          animation: false,
          backgroundColor: 'transparent',
          height: sortedCounts.length * 20,
          inverted: true,
          style: {
            fontFamily: tokens.font.global.family.base,
          },
          width: 560,
        },
        colors: sortedComponents.map(component =>
          DEPRECATED_COMPONENT_NAMES.some(name => name === component)
            ? tokens.color.global.visualization['negative'].default
            : tokens.color.global.visualization['category-1'].default,
        ),
        credits: {
          enabled: false,
        },
        legend: {
          enabled: false,
        },
        plotOptions: {
          column: {
            borderWidth: 0,
            colorByPoint: true,
            groupPadding: 0,
            maxPointWidth: 45,
            pointPadding: 0.1,
          },
          line: {
            lineWidth: 4,
            marker: false,
          },
          series: {
            animation: false,
            states: {
              hover: {
                enabled: false,
              },
            },
          },
        },
        series: [
          {
            data: sortedCounts,
            name: 'Count in use',
            type: 'column',
          },
        ],
        subtitle: {
          align: 'left',
          style: {
            color: tokens.color.global.font.custom.gray.default,
            fontSize: tokens.font.global.size.small,
          },
        },
        title: {
          align: 'left',
          margin: 12,
          style: {
            fontSize: tokens.font.global.size.body,
            fontWeight: tokens.font.global.weight.bold,
          },
          text: 'Components in use 1k+ times (red = deprecated)',
        },
        tooltip: {
          enabled: false,
        },
        xAxis: {
          categories: sortedComponents,
          labels: {
            autoRotation: undefined,
            style: {
              color: tokens.color.global.font.hierarchy.body.default,
            },
            x: -8,
          },
          lineColor: tokens.color.global.border.separation.weak.default,
          zIndex: 4,
        },
        yAxis: {
          gridLineColor: tokens.color.global.border.separation.weak.default,
          labels: {
            align: 'right',
            autoRotation: undefined,
            style: {
              color: tokens.color.global.font.hierarchy.body.default,
            },
            x: 3,
            y: 14,
          },
          plotLines: [
            {
              color: 'white',
              value: 0,
              width: 1,
              zIndex: 4,
            },
          ],
          tickAmount: 5,
          title: {
            align: 'high',
            margin: 0,
            reserveSpace: false,
            rotation: 0,
            style: {
              background: 'white',
              color: tokens.color.global.font.custom.gray.default,
              padding: '0 4px 4px 6px',
            },
            textAlign: 'left',
            useHTML: true,
            x: 35,
          },
        },
      }}
    />
  );
};

export default ComponentsChart;
