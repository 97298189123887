import React, { useEffect, useState } from 'react';
import { withPrefix } from 'gatsby';
import * as Ink from '@carta/ink';
import { getUsageDataGenerationDate } from '../../helpers/usage';
import Content from '../../components/Content/Content';
import Layout from '../../components/Layout';
import Top from '../../components/Usage/Top';
import ComponentsChart from '../../components/Usage/ComponentsChart';
import StyledLink from '../../components/Common/StyledLink';

const UsagePage = () => {
  const [componentsInUseCount, setComponentsInUseCount] = useState(0);
  const [components, setComponents] = useState({});
  const [date, setDate] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [uniquePageViewsCount, setUniquePageViewsCount] = useState(0);

  useEffect(() => {
    fetch(withPrefix('datasets/usage.json'))
      .then(response => response.json())
      .then(data => {
        const { count, date, ...components } = data;

        setComponents(components);
        setComponentsInUseCount(count);
        setDate(getUsageDataGenerationDate(date));
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    // TODO: Figure out how to get unqiue page views data dynamically
    fetch(withPrefix('datasets/pages-2023-03-20.json'))
      .then(response => response.json())
      .then(data => {
        const sumOfPageViews = data.pages.reduce((accumulator, currentPage) => accumulator + +currentPage.count, 0);

        setUniquePageViewsCount(sumOfPageViews);
        setIsLoading(false);
      });
  }, []);

  return (
    <Layout pageTitle="Usage">
      <Top
        title="Usage"
        subtitle={`Usage data as of ${date}`}
        viewSourceHref="https://github.com/carta/ink/blob/master/docs/src/pages/usage/index.tsx"
      />

      <Content>
        {isLoading && <Ink.Loader isLoading type="page" />}

        {!isLoading && (
          <Ink.Box top="xlarge">
            <Ink.Heading1>Visual usage</Ink.Heading1>
            <Ink.Paragraph>See in-depth ink usage statistics for the following groups</Ink.Paragraph>

            <Ink.Box top="large">
              <Ink.VStack spacing="xlarge">
                <Ink.HStack>
                  <Ink.Box minWidth={['none', '600px']}>
                    <Ink.NewTable>
                      <Ink.NewTable.Head>
                        <Ink.NewTable.Row>
                          <Ink.NewTable.HeadCell>Group</Ink.NewTable.HeadCell>
                          <Ink.NewTable.HeadCell>Metric</Ink.NewTable.HeadCell>
                          <Ink.NewTable.HeadCell>Date range</Ink.NewTable.HeadCell>
                          <Ink.NewTable.HeadCell align="right">Count</Ink.NewTable.HeadCell>
                        </Ink.NewTable.Row>
                      </Ink.NewTable.Head>

                      <Ink.NewTable.Body>
                        <Ink.NewTable.Row>
                          <Ink.NewTable.Cell>
                            <StyledLink to="/usage/components">Components</StyledLink>
                          </Ink.NewTable.Cell>

                          <Ink.NewTable.Cell>Instances in use</Ink.NewTable.Cell>
                          <Ink.NewTable.Cell>As of {date}</Ink.NewTable.Cell>

                          <Ink.NewTable.Cell align="right">
                            {new Intl.NumberFormat().format(componentsInUseCount ?? 0)}
                          </Ink.NewTable.Cell>
                        </Ink.NewTable.Row>

                        <Ink.NewTable.Row>
                          <Ink.NewTable.Cell>
                            <StyledLink to="/usage/pages">Pages</StyledLink>
                          </Ink.NewTable.Cell>

                          <Ink.NewTable.Cell>Unique page views</Ink.NewTable.Cell>
                          <Ink.NewTable.Cell>March 14–20, 2023</Ink.NewTable.Cell>

                          <Ink.NewTable.Cell align="right">
                            {new Intl.NumberFormat().format(uniquePageViewsCount ?? 0)}
                          </Ink.NewTable.Cell>
                        </Ink.NewTable.Row>
                      </Ink.NewTable.Body>
                    </Ink.NewTable>
                  </Ink.Box>
                </Ink.HStack>

                <Ink.HStack>
                  <Ink.Block trim>
                    <Ink.Box minWidth={['none', '560px']}>
                      <ComponentsChart {...{ components }} />
                    </Ink.Box>
                  </Ink.Block>
                </Ink.HStack>
              </Ink.VStack>
            </Ink.Box>
          </Ink.Box>
        )}
      </Content>
    </Layout>
  );
};

export default UsagePage;
